import * as React from "react";

export default function Careers() {
  React.useEffect(() => {
    window.location.replace("https://recruitlab.co.uk/i/levercode/jobs/1/trailblazers");
  }, []);
  return (
    <>Redirecting...</>
  )
}
